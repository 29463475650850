import React from "react"
import styled from "styled-components"
import Line from "../UI/line/line"
import SubHeader from "./subHeading"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const detailedPageHeader = ({
  heading,
  subHeading,
  bg,
  compact,
  bgPosition,
  mobileHeight,
}) => (
  <PageHeader
    bg={bg}
    compact={compact}
    bgPosition={bgPosition}
    mobileHeight={mobileHeight}
  >
    <PageHeaderInner>
      <h1>{heading}</h1>
      <Line />
      {subHeading && <SubHeader subHeading={subHeading} />}
    </PageHeaderInner>
  </PageHeader>
)
export default detailedPageHeader

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const PageHeader = styled.div`
  background-color: ${(props) => props.theme.green};
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: ${(props) => props.bgPosition};
  background-position-y: center;
  min-height: ${(props) => {
    const compact = props.compact

    if (compact) {
      return "560px"
    } else {
      return "700px"
    }
  }};
  padding: 95px 10px 10px 10px;
  display: grid;
  grid-template-columns: 1fr;
  align-content: center;
  justify-items: left;
  width: 100%;
  h1 {
    color: #fff;
    margin: 10px 0;
    font-size: 60px;
    font-weight: 600;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.36);
  }
  h3 {
    color: #fff;
  }
  p {
    color: #fff;
  }
  border-bottom: 8px solid ${(props) => props.theme.gold};
  @media (max-width: 1024px) {
    h1 {
      font-size: 60px;
    }
  }
  @media (max-width: 800px) {
    min-height: 460px;
    h1 {
      font-size: 60px;
    }
  }
  @media (max-width: 500px) {
    min-height: ${(props) => props.mobileHeight};
    padding: 40px 10px 10px 10px;
    align-content: start;
    h1 {
      font-size: 30px;
      margin-left: 20px;
    }
  }
`

const PageHeaderInner = styled.div`
  max-width: ${(props) => props.theme.maxWidth};
  margin: 0 auto;
  width: 100%;
`
