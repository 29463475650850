import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import posed from "react-pose"
import styled from "styled-components"
import Layout from "../../components/layout"
import Container from "../../components/Container"
import Header from "../../components/header/header"
import Footer from "../../components/footer/footer"
import PostHeader from "../../components/pageHeader/postHeader"
import SeoComponent from "../../components/seoComponent"
import fallbackImage from "../../images/blog-generic-cover.png"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const SinglePost = ({ pageContext: { post, nextPost } }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    let fadeIn = setTimeout(() => setIsVisible(true), 500)
    return () => {
      clearTimeout(fadeIn)
    }
  }, [])

  return (
    <Layout>
      <Header />
      <SeoComponent seo={post.seo} />
      <PostHeader
        heading={post.title}
        subHeading={post.excerpt}
        bg={post.featuredImage?.node?.sourceUrl || fallbackImage}
        compact={false}
        mobileHeight="310px"
        categories={post.categories}
        authorAvatar={post.author.node.avatar.url}
        authorName={post.author.node.name}
        postDate={post.date}
      />
      <PageAnimationWrapper pose={isVisible ? "visible" : "hidden"}>
        <Container>
          <BlogInner>
            <div dangerouslySetInnerHTML={{ __html: post.content }} />
            <div className="categories">
              Categories:
              {post.categories.nodes.map((item2) => (
                <a href={"/category/" + item2.slug}>{item2.name}</a>
              ))}
            </div>
            <AuthorBox>
              <img
                src={post.author.node.avatar.url}
                alt={post.author.node.name}
              />
              <div>
                <p>
                  {post.author.node.name} <br />
                  {post.date}
                </p>
              </div>
            </AuthorBox>
          </BlogInner>
        </Container>
        <BlogAllLink>
          <Link to="/newsroom">Back to Newsroom</Link>
        </BlogAllLink>
      </PageAnimationWrapper>
      <Footer />
    </Layout>
  )
}
export default SinglePost

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const PageAnimationWrapper = posed.div({
  visible: { y: 0, opacity: 1 },
  hidden: { y: 100, opacity: 0 },
})

const BlogInner = styled.div`
  max-width: ${(props) => props.theme.maxWidthSkinny};
  margin: 0 auto;
  h2,
  h3,
  h4 {
    border-bottom: 2px solid ${(props) => props.theme.grey3};
    margin: 1.5em 0 1em 0;
  }
  .wp-block-gallery {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-gap: 20px;
    &.columns-1 {
      grid-template-columns: 1fr;
    }
    &.columns-2 {
      grid-template-columns: repeat(2, 1fr);
    }
    &.columns-3 {
      grid-template-columns: repeat(3, 1fr);
    }
    img,
    li {
      margin: 0;
    }
  }
  a {
    text-decoration: underline;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: ${(props) => props.theme.yellow};
    }
  }
  .categories a {
    color: ${(props) => props.theme.green};
    background-color: #fff;
    border-radius: 5px;
    margin-right: 10px;
    padding: 5px;
    :hover {
      background-color: ${(props) => props.theme.green};
      color: #fff;
    }
  }
  .categories {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media (max-width: 800px) {
    .wp-block-gallery {
      grid-template-columns: 1fr !important;
    }
    .blocks-gallery-item figure img {
      width: 100%;
    }
  }
`
const AuthorBox = styled.div`
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 11fr;
  p {
    margin: 0;
  }
  img {
    width: 50px;
    border: 2px solid ${(props) => props.theme.gold};
    border-radius: 50%;
    margin: 0;
  }
`

const BlogAllLink = styled.div`
  text-align: center;
  border-top: 1px solid ${(props) => props.theme.gold};
  padding: 40px;
  a {
    background-color: ${(props) => props.theme.green};
    padding: 12px 40px;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    &:hover {
      background: ${(props) => props.theme.dark};
      color: ${(props) => props.theme.white};
      transform: scale(1.05);
    }
  }
`

// ///////////////////////////////////////
// // 🔎 Queries
// ///////////////////////////////////////
// export const query = graphql`
//   query PostQuery($slug: String) {
//     post: wpPost(slug: { eq: $slug }) {
//       seo {
//         ...SEO
//       }
//       title
//       slug
//       date(formatString: "MMMM DD, YYYY")
//       excerpt
//       content
//       categories {
//         nodes {
//           name
//           slug
//         }
//       }
//       author {
//         node {
//           name
//           avatar {
//             url
//           }
//         }
//       }
//       featuredImage {
//         node {
//           sourceUrl
//         }
//       }
//     }
//   }
// `
